import React from 'react'

import './testimonial.scss'

function Testimonial({ copy, author, image }) {
  return (
    <div className="testimonial-container">
      <div className="testimonial-row">
        <img src={image} alt="testimonial one" className="testimonial-image" />
      </div>
      <div className="testimonial-column">
        <div className="testimonial-copy">
          <p>
            <i>{copy}</i>
          </p>
        </div>
        <div className="testimonial-author">{author}</div>
      </div>
    </div>
  )
}

export default Testimonial
