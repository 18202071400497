import React from 'react'
import { Link } from 'gatsby'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import Layout from '../components/layout'
import SEO from '../components/seo'
import jumbotron from '../images/jumbotron.jpeg'
import regulatorBefore from '../images/regulatorbefore.jpg'
import regulatorMiddle from '../images/regulatormiddle.jpg'
import regulatorAfter from '../images/regulatorafter.jpg'
import torchBefore from '../images/torchbefore.jpg'
import torchMiddle from '../images/torchmiddle.jpg'
import torchAfter from '../images/torchafter.jpg'

import './index.scss'
import Testimonial from '../components/testimonial'
import Testimonials from '../components/testimonials'
import Cleaned from '../components/cleaned'
import Magnify from '../components/magnify'
import Tested from '../components/tested'
import owner from '../images/owner.jpeg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="jumbotron-section">
      <img src={jumbotron} alt="welder" className="background-image" />
      <div className="half-block jumbotron">
        Superior Gas Apparatus Equipment Repair
        <div className="subtitle">- Established 2017</div>
      </div>
    </section>
    <div className="section-title">WHAT WE DO</div>
    <div className="subsection">
      <h3>-- We repair welding equipment --</h3>
    </div>
    <div className="checklist">
      <ul>
        <li>&nbsp;&nbsp;QUICKLY</li>
        <li>&nbsp;&nbsp;EFFECTIVELY</li>
        <li>&nbsp;&nbsp;INEXPENSIVELY</li>
      </ul>
    </div>
    <section className="what-section">
      <CarouselProvider
        naturalSlideWidth={368}
        naturalSlideHeight={289}
        totalSlides={6}
        step={3}
        visibleSlides={3}
        orientation="horizontal"
        className="before-after-carousel"
        infinite={true}
        isPlaying={true}
      >
        <Slider>
          <Slide className="individual-slide" index={0}>
            <img src={regulatorBefore} alt="regulator before" />
          </Slide>
          <Slide className="individual-slide" index={1}>
            <img src={regulatorMiddle} alt="regulator broken down" />
          </Slide>
          <Slide className="individual-slide" index={2}>
            <img src={regulatorAfter} alt="regulator after" />
          </Slide>
          <Slide className="individual-slide" index={3}>
            <img src={torchBefore} alt="torch before" />
          </Slide>
          <Slide className="individual-slide" index={4}>
            <img src={torchMiddle} alt="torch broken down" />
          </Slide>
          <Slide className="individual-slide" index={5}>
            <img src={torchAfter} alt="torch after" />
          </Slide>
        </Slider>
        {/*<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>*/}
        {/*  <ButtonBack>{`<`}</ButtonBack>*/}
        {/*  <ButtonNext>{`>`}</ButtonNext>*/}
        {/*</div>*/}
      </CarouselProvider>
    </section>
    <div className="section-title">OUR PROCESS</div>
    <section className="specialties-section">
      <div className="specialties-content">
        <div className="third-block specialties">
          <div className="specialties-inner">
            <div className="specialties-row background-blue">
              <div className="circle-border">
                <Cleaned />
              </div>
            </div>
            <hr />
            <div className="specialties-row">- CLEANING -</div>
            <div className="specialties-row left-justified">
              Upon arrival all apparatus are broken down and meticulously
              cleaned and prepared for inspection
            </div>
          </div>
        </div>
        <div className="third-block specialties">
          <div className="specialties-inner">
            <div className="specialties-row background-black">
              <div className="circle-border">
                <Magnify />
              </div>
            </div>
            <hr />
            <div className="specialties-row">- INSPECTION & REPLACE -</div>
            <div className="specialties-row left-justified">
              A thorough inspection is completed and all soft parts are removed
              and replaced with new parts
            </div>
          </div>
        </div>
        <div className="third-block specialties">
          <div className="specialties-inner">
            <div className="specialties-row background-green">
              <div className="circle-border">
                <Tested />
              </div>
            </div>
            <hr />
            <div className="specialties-row">- TESTING -</div>
            <div className="specialties-row left-justified">
              <span>
                Finally, parts are reassembled and undergo rigorous tests such
                as underwater and factory flame testing
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="section-title">OUR CUSTOMERS</div>
    <section className="customers-section">
      <div className="customers-row">We repair equipment for</div>
      <div className="customers-row">
        <span className="customer-orb" style={{ background: '#f6934b' }}>
          Welding Suppliers
        </span>
        <span className="customer-orb" style={{ background: '#4b81f6' }}>
          High Schools
        </span>
        <span className="customer-orb" style={{ background: '#dc4bf6' }}>
          Colleges
        </span>
        <span className="customer-orb" style={{ background: '#f6e84b' }}>
          Fabricators
        </span>
        <span className="customer-orb" style={{ background: '#f6934b' }}>
          Pipeline Welders
        </span>
        <span className="customer-orb" style={{ background: '#4b81f6' }}>
          Homeowners
        </span>
      </div>
    </section>
    <div className="section-title">TESTIMONIALS</div>
    <Testimonials />
  </Layout>
)

export default IndexPage
