import React, { useState, useEffect, useRef } from 'react'
import Testimonial from './testimonial'
import mountainWest from '../images/mountainwest.png'
import timpView from '../images/timpview.png'
import nielsens from '../images/Nielsens.jpg'
import Layout from './layout'

const testimonialOne = `
Nielsen's Arc Service  is an established welding company. Throughout the years we have used Rocky Mountain Torch to rebuild our torch heads and gauges. The service we have received from Rocky Mountain Torch has been professional, fast, and fair priced. We appreciate the work they do and will continue to use them throughout or professional career.
`
const testimonialTwo = `
Cory fixes my torches so fast it scares me.  I have to have all my PO requests and approvals in place before I call him, because he often comes the day I do so. The first time he fixed something for me, he was waiting at the door -- the day after he picked them up -- to give them to me when I got to the school at 6:30 in the morning.  Not only a skilled repairman, but a great guy, I am always happy to see him.  I am so glad that he's here for us!
`
const testimonialThree = `
We’ve been using Rocky Mountain Torch and Regulator for a few years now. Cory is always on top of getting our repair items picked up and I’m always surprised by the turnaround time. We really batter up our torches out in the field and they always look like they are one step from the dumpster, but when they come back from Rocky Mountain Torch they look and run as good as the day they came out of the box.
`

const list = [
  {
    image: nielsens,
    author: '- Jesse, Nielsens Arc Service',
    copy: testimonialOne,
  },
  {
    image: mountainWest,
    author: '- Brett, Mountain West Industrial',
    copy: testimonialThree,
  },
  {
    image: timpView,
    author: '- Daniel, Timpview High School',
    copy: testimonialTwo,
  },
]

function Testimonials({ time = 15000 }) {
  const [className, setClassName] = useState('lefty')
  const classNameRef = useRef(className)
  classNameRef.current = className
  useEffect(() => {
    setInterval(() => {
      if (classNameRef.current === 'lefty') {
        setClassName('centery')
      } else if (classNameRef.current === 'centery') {
        setClassName('righty')
      } else {
        setClassName('lefty')
      }
    }, time)
  }, [])

  const classNames = `testimonial-section ${className}`
  return (
    <section className={classNames}>
      {list.map(single => (
        <div className="half-block testimonial" key={single.author}>
          <Testimonial
            author={single.author}
            copy={single.copy}
            image={single.image}
          />
        </div>
      ))}
    </section>
  )
}

export default Testimonials
